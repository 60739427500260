import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError, handleZodError } from './api'
import { COUNTRIES } from '../constants'
import { cleanObject } from '../helpers'
import { clientDashboardSchema, clientExtendedSchema, clientSchema } from './schemas'

//* ---------------- APIS ---------------- *//
export async function getClients(rawParams?: Record<string, unknown>) {
  try {
    const cleanData = cleanObject({ ...rawParams })
    const checkedData = getClientParamsSchema.parse(cleanData)
    const params = cleanObject(checkedData)

    const response = await API.get('customers/dashboard', { params })
    const clients = z.array(clientDashboardSchema).parse(response.data)

    return { clients, totalClients: response.headers.total }
  } catch (error) {
    return handleError(error, 'Clients')
  }
}

export async function getClient(id: number) {
  try {
    const response = await API.get(`customers/${id}`)
    const client = clientExtendedSchema.parse(response.data)

    return client
  } catch (error) {
    return handleError(error, 'Client')
  }
}

export async function createClient(data: Record<string, unknown>) {
  try {
    const cleanData = cleanObject({ ...data })
    const checkedData = newClientSchema.safeParse(cleanData)

    if (checkedData.success) {
      const response = await API.post('customers', checkedData.data)
      const client = clientSchema.parse(response.data)
      return { client }
    } else {
      const errors = handleZodError(checkedData.error)
      return { errors }
    }
  } catch (error) {
    return handleError(error, 'Create Client')
  }
}

export async function updateClient(id: number, data: Record<string, unknown>) {
  try {
    const cleanData = cleanObject({ ...data })
    const checkedData = newClientSchema.safeParse(cleanData)

    if (checkedData.success) {
      const response = await API.put(`customers/${id}`, checkedData.data)
      const client = clientSchema.parse(response.data)
      return { client }
    } else {
      const errors = handleZodError(checkedData.error)
      return { errors }
    }
  } catch (error) {
    return handleError(error, 'Update Client')
  }
}

export async function deleteClient(id: number, data: Record<string, unknown>) {
  try {
    await API.put(`customers/${id}`, { ...data, customer_status: 'deleted' })
    return { success: true }
  } catch (error) {
    return handleError(error, 'Delete Client')
  }
}

//* ---------------- PARAMS ---------------- *//
const getClientParamsSchema = z
  .object({
    q: z.string().optional(),
    haveBought: z.union([z.literal('true'), z.literal('false')]).optional(),
    startDate: z.coerce.date().optional(),
    endDate: z.coerce.date().optional(),
    garmentTypes: z.string().optional(),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(100),
  })
  .transform(({ haveBought, garmentTypes, ...rest }) => ({
    ...rest,
    haveBought: haveBought === 'true' ? haveBought : undefined,
    garmentType: garmentTypes?.split(','),
  }))
  .transform(objectToSnake)

const newClientSchema = z
  .object({
    name: z.string({ required_error: 'Name is required' }),
    gender: z.union([z.literal('male'), z.literal('female')]).optional(),
    company: z.string().nullable().default(null),
    nameLabel: z.string().nullable().default(null),
    laundryMarker: z.string().nullable().default(null),
    monogram: z.string().nullable().default(null),
    addressType: z.union([z.literal('residential'), z.literal('commercial')]).optional(),
    address1: z.string().nullable().default(null),
    address2: z.string().nullable().default(null),
    city: z.string().nullable().default(null),
    state: z.string().nullable().default(null),
    zip: z.string().nullable().default(null),
    country: z.enum(COUNTRIES).optional(),
    cellPhone: z.string().nullable().default(null),
    homePhone: z.string().nullable().default(null),
    email: z.string().email().nullable().default(null),
  })
  .transform(objectToSnake)
  .transform(
    ({ company, address_1, address_2, city, state, zip, country, cell_phone, home_phone, email, ...data }) => ({
      customer_company: company,
      customer_address1: address_1,
      customer_address2: address_2,
      customer_city: city,
      customer_state: state,
      customer_zip: zip,
      customer_country: country,
      customer_cell: cell_phone,
      customer_home_phone: home_phone,
      customer_email: email,
      ...data,
    }),
  )

//* ---------------- TYPES ---------------- *//

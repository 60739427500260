//? Helpers used for snakeCase function
const wordSeparators = /[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+/
const capitals = /[A-Z\u00C0-\u00D6\u00D9-\u00DD]/g

/**
 *
 * @param str string to be converted to snake_case
 * @returns snake_case string
 */
export function snakeCase(str: string) {
  //replace capitals with space + lower case equivalent for later parsing
  str = str.replace(capitals, match => {
    return ' ' + (match.toLowerCase() || match)
  })
  return str.trim().split(wordSeparators).join('_')
}

//? Helpers used for camelCase function
const wordSeparatorsRegEx = /[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+/
const basicCamelRegEx = /^[a-z\u00E0-\u00FCA-Z\u00C0-\u00DC][\d|a-z\u00E0-\u00FCA-Z\u00C0-\u00DC]*$/
const fourOrMoreConsecutiveCapsRegEx = /([A-Z\u00C0-\u00DC]{4,})/g
const allCapsRegEx = /^[A-Z\u00C0-\u00DC]+$/

function deCap(match: string, endOfWord: boolean) {
  const arr = match.split('')
  const first = arr.shift()?.toUpperCase()
  const last = endOfWord ? arr.pop()?.toLowerCase() : arr.pop()
  return first + arr.join('').toLowerCase() + last
}

/**
 *
 * @param str string to be converted to camelCase
 * @returns camelCase string
 */
export function camelCase(str: string) {
  const words = str.split(wordSeparatorsRegEx)
  const len = words.length
  const mappedWords = new Array(len)
  for (let i = 0; i < len; i++) {
    let word = words[i] as string
    if (word === '') {
      continue
    }
    const isCamelCase = basicCamelRegEx.test(word) && !allCapsRegEx.test(word)
    if (isCamelCase) {
      word = word.replace(fourOrMoreConsecutiveCapsRegEx, (match, _p1, offset) => {
        return deCap(match, word.length - offset - match.length == 0)
      })
    }
    let firstLetter = word[0] as string
    firstLetter = i > 0 ? firstLetter.toUpperCase() : firstLetter.toLowerCase()
    mappedWords[i] = firstLetter + (!isCamelCase ? word.slice(1).toLowerCase() : word.slice(1))
  }
  return mappedWords.join('')
}

/**
 *
 * @param str string to be capitalized
 * @returns capitalized string
 */
export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 *
 * @param str string to be converted to Capital Case
 * @returns Capital Case string
 */
export function capitalCase(str: string) {
  return deCase(str).split(' ').map(capitalize).join(' ')
}

/**
 *
 * @param str string to have case removed
 * @returns string with case removed
 */
export function deCase(str: string) {
  const snakeString = snakeCase(str)
  return snakeString.split('_').join(' ')
}
